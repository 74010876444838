
import { Utils } from '@bright-spaces/engine-3d/dist/helpers/Utils'
import { CurrentView, FloorViewMode, SpaceFloorViewMode } from '~/store/building/-constants'
import projectConstants from '~/store/project/-constants'
import modalConstants from '~/store/modal/-constants'
import tutorialConstants from '~/store/tutorial/-constants'
import universeConstants from '~/store/universe/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'

export default {
  name: 'MoleculeBuildingOrientationBar',
  props: {
    infoEnabled: {
      required: false,
      type: Boolean,
      default: true
    },
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    },
    disableExploreSpacePage: {
      required: false,
      type: Boolean,
      default: false
    },
    disableRequestOffer: {
      required: false,
      type: Boolean,
      default: false
    },
    disableWalkThrough: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewModes: FloorViewMode,
      isSpaceSelectionOpen: false,
      shortlistFlag: this.isInShortlist(),
      isButtonFitoutActive: true
    }
  },
  computed: {
    shortlistState() {
      return this.$store.getters?.getShortlist || false
    },
    activeProjectSlug() {
      return this.$store.state.project.activeProject
    },
    apartmentViewMode() {
      return this.$store.state?.universe?.apartmentView || false
    },
    shortlist() {
      return this.$store.state?.requestOffer?.spaces
    },
    open() {
      return this.$store.state?.universe?.infoBox?.open || false
    },
    building() {
      return this.$store.state.base?.landlordDetails?.buildings?.find(
        (b) => b.id === this.space.building_id
      )
    },
    space() {
      let space = {}
      if (this.$route.params.space) {
        space =
          this.$store.state.base?.landlordDetails?.spaces.find((s) => {
            return s.id === parseInt(this.$route.params.space)
          }) || {}
      } else if (this.$store.state.project?.project?.currentSpaceId) {
        space =
          this.$store.state.base?.landlordDetails?.spaces.find((s) => {
            return s.id === parseInt(this.$store.state.project?.project?.currentSpaceId)
          }) || {}
      }
      return space
    },
    buildingStore() {
      return this.$store.state.building
    },
    spaceViewMode() {
      return this.buildingStore.spaceFloorViewMode
    },
    fitoutData() {
      return this.buildingStore.fitoutData.data
    },
    isFloorView() {
      return this.buildingStore.currentView === CurrentView.FLOOR
    },
    viewMode() {
      return this.buildingStore.floorViewMode
    },
    currentSpace() {
      return this.buildingStore.space.spaceData || {}
    },
    spaceBuilding() {
      return this.$store.state.base?.landlordDetails.buildings.find(
        (b) => b.id === this.currentSpace.building_id
      )
    },
    spaceFloor() {
      return this.$store.state.base?.landlordDetails.floors.find(
        (f) => f.id === this.currentSpace.floor_id
      )
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spacesCounter() {
      return this.$store.state.requestOffer.spaces.length
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    activeSpace() {
      return this.$store.state.base?.landlordDetails.currentSpaceId
    },
    sqmInfo() {
      // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
      // const balconySurface =
      //   this.space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
      //     ?.surface || 0
      // const gardenSurface =
      //   this.space?.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())
      //     ?.surface || 0
      return {
        // total_sqm: Number((this.space.constructed_sqm + balconySurface + gardenSurface).toFixed(2)),
        // useful_sqm: Number((this.space.util_sqm + balconySurface + gardenSurface).toFixed(2))
        total_sqm: Number(this.space.constructed_sqm.toFixed(2)),
        useful_sqm: Number(this.space.util_sqm.toFixed(2))
      }
    },
    cameraCoordinates() {
      const cameraCoordinates = this.space?.camera_coordinates || []
      return [...cameraCoordinates]?.sort((a, b) => a.order - b.order) || []
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    projectSettings() {
      return this.$store.getters.getActiveProjectSettings
    },
    initWithFitoutActive() {
      return this.projectSettings?.floor?.initWithoutFitout || false
    },
    generalConfig() {
      return this.$store?.state?.base?.meta?.generalConfig
    },
    activeProject() {
      return this.$store?.state?.project?.activeProject
    },
    priceInfo() {
      return this.generalConfig?.priceInfo?.[this.activeProject]?.text || ''
    }
  },
  watch: {
    shortlist() {
      this.shortlistFlag = this.isInShortlist()
    },
    viewMode(value, old) {
      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem
        })
      }
    },
    isSpaceSelectionOpen(value) {
      if (value) {
        this.$refs.listNode.classList.add('active')
        this.$refs.hideText.style.display = 'block'
        this.$refs.requestOfferText.style.display = 'none'
      } else {
        this.$refs.listNode.classList.remove('active')
        this.$refs.requestOfferText.style.display = 'block'
        this.$refs.hideText.style.display = 'none'
      }
    }
  },
  async created() {
    this.isButtonFitoutActive = this.initWithFitoutActive
      ? !this.initWithFitoutActive
      : this.isButtonFitoutActive
    if (this.$route.params.spaceId)
      await this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
        parseInt(this.$route.params.spaceId)
      )
  },
  methods: {
    isInShortlist() {
      if (this.$route.params.spaceId) {
        return (
          this.$store.state?.requestOffer?.spaces.filter((s) => {
            return s.id === parseInt(this.$route.params.spaceId)
          }).length > 0
        )
      } else if (this.space) {
        return (
          this.$store.state?.requestOffer?.spaces.filter((s) => {
            return s.id === this.space.id
          }).length > 0
        )
      } else return false
    },
    addToShortlist(space) {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
        {
          open: true
        }
      )
      setTimeout(
        () =>
          this.$store.dispatch(
            universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
            {
              open: false
            }
          ),
        5000
      )
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        {
          ...space,
          building: this.building,
          floor: space.floor
        }
      )
      this.shortlistFlag = this.isInShortlist()
    },
    checkEmptyObject(obj) {
      return Object.keys(obj).length > 0
    },
    changeCurrentSpace() {
      let space = []
      if (this.$store.state.project?.project.currentSpaceId) {
        space = this.$store.state.project?.project?.spaces.filter((s) => {
          return s.id === this.$store.state.project?.project.currentSpaceId
        })
      }
      return space[0] ? space[0] : {}
    },
    handleInfoTrigger() {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open
        }
      )
      if (window.innerWidth < 768) {
        if (!this.$store.state.tutorial.INFO_MOBILE)
          this.$store.dispatch(tutorialConstants.namespaceAction.TRIGGER_PANEL, 'INFO_MOBILE')
      } else if (!this.$store.state.tutorial.INFO)
        this.$store.dispatch(tutorialConstants.namespaceAction.TRIGGER_PANEL, 'INFO')
    },
    handleCloseTrigger() {
      this.$emit('close')
    },
    activateFitoutButton() {
      this.isButtonFitoutActive = !this.isButtonFitoutActive
      this.toggleFitout()
      if (window.innerWidth < 768 && !this.$store.state.tutorial.FITOUT_CHANGER_MOBILE) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'FITOUT_CHANGER_MOBILE'
        )
      }
      if (window.innerWidth >= 768 && !this.$store.state.tutorial.FITOUT_CHANGER) {
        this.$store.dispatch(tutorialConstants.namespaceAction.TRIGGER_PANEL, 'FITOUT_CHANGER')
      }
    },
    toggleFitout() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'fitout_toggle_change',
          payload: {
            fitout: {}
          }
        })
      }
      const manager = this.engine3d.getClientManager()
      manager?.toggleFitout()
      manager?.showLevelElements(this.spaceViewMode)
      manager?.updateMinimap()
      if (this.viewMode === FloorViewMode.FIRST_PERSON) {
        manager.showLevelElements(SpaceFloorViewMode.ALL)
      }
      Utils.focusCanvas()
    },
    changeViewMode3D(mode) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: '3d'
          }
        })
      }
      if (window.innerWidth < 768) {
        if (this.viewMode === FloorViewMode.FIRST_PERSON) {
          if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_3D_MOBILE)
            this.$store.dispatch(
              tutorialConstants.namespaceAction.TRIGGER_PANEL,
              'VIEW_CHANGER_TO_2D_3D_MOBILE'
            )
        } else if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_VT_MOBILE)
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'VIEW_CHANGER_TO_2D_VT_MOBILE'
          )
      } else if (this.viewMode === FloorViewMode.FIRST_PERSON) {
        if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_3D)
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'VIEW_CHANGER_TO_2D_3D'
          )
      }
      this.$store.dispatch('building/changeViewMode', mode)

      const manager = this.engine3d.getClientManager()
      manager.showLevelElements(this.spaceViewMode)
      manager.switchCamera(mode)
    },
    changeViewModeFirstPerson() {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'view_mode_change',
          payload: {
            mode: 'VT'
          }
        })
      }
      if (window.innerWidth < 768) {
        if (this.viewMode === FloorViewMode.FIRST_PERSON) {
          if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_3D_MOBILE)
            this.$store.dispatch(
              tutorialConstants.namespaceAction.TRIGGER_PANEL,
              'VIEW_CHANGER_TO_2D_3D_MOBILE'
            )
        } else if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_VT_MOBILE)
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'VIEW_CHANGER_TO_2D_VT_MOBILE'
          )
      } else if (this.viewMode === FloorViewMode.FIRST_PERSON) {
        if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_3D) {
          this.$store.dispatch(
            tutorialConstants.namespaceAction.TRIGGER_PANEL,
            'VIEW_CHANGER_TO_2D_3D'
          )
        }
      } else if (!this.$store.state.tutorial.VIEW_CHANGER_TO_2D_VT) {
        this.$store.dispatch(
          tutorialConstants.namespaceAction.TRIGGER_PANEL,
          'VIEW_CHANGER_TO_2D_VT'
        )
      }

      if (this.viewMode === FloorViewMode.FIRST_PERSON) {
        this.$store.dispatch('building/changeSpaceViewMode', SpaceFloorViewMode.ALL)
        return this.changeViewMode3D(FloorViewMode.DEFAULT)
      }

      this.$store.dispatch('building/changeViewMode', FloorViewMode.FIRST_PERSON)

      const manager = this.engine3d.getClientManager()
      manager.switchCamera(FloorViewMode.FIRST_PERSON)
      let level = this.spaceViewMode
      if (this.spaceViewMode === SpaceFloorViewMode.ALL) {
        level = SpaceFloorViewMode.GROUND
      }
      this.$store.dispatch('building/changeSpaceViewMode', level)
      manager.showLevelElements(level)
      manager.updateLevelControls(level)
      setTimeout(() => {
        manager.showLevelElements(SpaceFloorViewMode.ALL)
      }, 10)
    },
    openRequestOfferModal() {
      if (!this.spacesCounter) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    openRequestOfferModalWithSpace(space, building) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer',
        data: { space: { ...space, building } }
      })
      document.body.classList.add('disable-scroll')
    },
    closeSpaceSelectionDetails() {
      this.isSpaceSelectionOpen = false
    },
    removeSpaceFromCart(space) {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
    },
    spaceIsRequested(spaceId) {
      return this.requestedSpaces.find((space) => space.id === spaceId)
    },
    getMappedFloorText(floor) {
      let text = ''
      // TODO: refactor this Petrichi fix :P
      switch (parseInt(floor, 10)) {
        case -3:
          text = `${this.$t('FloorRo')} -3<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -2:
          text = `${this.$t('FloorRo')} -2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -1:
          text = `${this.$t('FloorRo')} -1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 0:
          text = `${this.$t('Ground Floor')}`
          break
        case 1:
          text = `${this.$t('FloorRo')} 1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 2:
          text = `${this.$t('FloorRo')} 2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 3:
          text = `${this.$t('FloorRo')} 3<sup class="superior-text">${this.$t(
            'rd'
          )}</sup>  ${this.$t('Floor')}`
          break
        default:
          text = `${this.$t('FloorRo')} ${floor}<sup class="superior-text">${this.$t(
            'th'
          )}</sup>  ${this.$t('Floor')}`
      }
      return text
    },
    displayLevelButton(searchCriteria = []) {
      return (
        this.cameraCoordinates.length > 0 &&
        this.cameraCoordinates.find((c) => searchCriteria.includes(c.level))
      )
    }
  }
}
